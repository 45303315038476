html {
  height: 100%;
  min-height: 100%;
  background-color: black;
}

#root {
  height: 100%;
  display: flex;
  flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.App {
  text-align: center;
  width: 100%;
  justify-content: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  height: 100%;
}

body {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.top-bar {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #55b5b3;
  background-color: black;
  min-height: 10vh;
  border-bottom: 2px solid #55b5b3;
  width: 100%;
}

@import url('https://fonts.googleapis.com/css?family=Cairo');
.title {
  font-size: 2em;
  color: white;
  font-family: 'Cairo';
  font-weight: 600;
}

.question-icon {
	position: absolute;
	display: block;
	transform: scaleX(-1);
	left: 0.5em;
	font-size: 2em;
  color: #aaf2d8;
}

.share-icon {
	position: absolute;
	display: block;
	transform: scaleX(-1);
	left: 2em;
	font-size: 2em;
  color: #aaf2d8;
}

.chart-icon {
	position: absolute;
	display: block;
	right: 0.5em;
	font-size: 2em;
  color: #aaf2d8;
}

.prize-icon {
	position: absolute;
	display: block;
	right: 2em;
	font-size: 2em;
  color: #aaf2d8;
}

@media screen and (max-width: 280px) {
  .question-icon {
    position: absolute;
    display: block;
    transform: scaleX(-1);
    left: 0.25em;
    font-size: 2em;
    color: #aaf2d8;
  }

  .share-icon {
    position: absolute;
    display: block;
    transform: scaleX(-1);
    left: 1.5em;
    font-size: 2em;
    color: #aaf2d8;
  }

  .chart-icon {
    position: absolute;
    display: block;
    right: 0.25em;
    font-size: 2em;
    color: #aaf2d8;
  }

  .prize-icon {
    position: absolute;
    display: block;
    right: 1.5em;
    font-size: 2em;
    color: #aaf2d8;
  }

}

.volume-icon {
  font-size: 2em;
  color: black;
}

.play-icon {
	font-size: 2em;
  color: #aaf2d8;
}

.guesses-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 0.5;
  margin-top: 1rem;
}

.guess-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21rem;
  height: 2rem;
  border: 1px solid rgba(85, 181, 179, 0.5);
  text-align: center;
  color: white;
  font-size: 20px;
}

.player {
  display: flex;
  flex: 0.15;
  align-items: center;
  justify-content: center;
}

.guessboard-div {
  display: flex;
  flex-direction: row;
  flex: 0.1;
}

.single-player-div {
  width: 2rem;
  height: 1rem;
  border: 1px solid #aaf2d8;
}

.winning-text {
  font-size: 2em;
  color: #55b5b3;
  font-family: 'Cairo';
  font-weight: 600;
  line-height: 1rem;
}

.stats-button-blue {
	background: #55b5b3;
	border: none;
	border-radius: 0.5em;
	font-size: 1em;
	padding: 0.2em;
	margin-right: 0.1em;
	margin-left: 0.1em;
	margin-top: 0.1em;
	margin-bottom: 0.1em;
	width: 2em;
	color: black;
}

.stats-button-green {
	background: #aaf2d8;
	border: none;
	border-radius: 0.5em;
	font-size: 1em;
	padding: 0.3em;
	margin-right: 0.1em;
	margin-left: 0.1em;
	margin-top: 0.1em;
	margin-bottom: 0.1em;
	width: 2em;
	color: black;
}

.skip-div {
  display: flex;
  flex: 0.25;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skip-button {
  background: #aaf2d8;
	border: none;
	border-radius: 0.5em;
	font-size: 1em;
	color: black;
  line-height: 3rem;
  width: 7rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.skip-button:disabled,
.skip-button[disabled]{
  background-color: rgba(170, 242, 216, 0.5);
}

.select-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.stat-box {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.modal-stat-text {
  font-size: 1rem;
}

.result-message {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.margin-bottom-1 {
  margin-bottom: 1em;
}

.surah-name-div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.end-res-share {
  display: flex;
  flex-direction: row;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.prize-submit-button {
  background: #55b5b3;
	border: none;
	border-radius: 0.5em;
	font-size: 1.1em;
	padding: 0.2em;
	margin-right: 0.1em;
	margin-left: 0.1em;
	margin-top: 0.1em;
	margin-bottom: 0.1em;
	color: black;
  padding-right: 2rem;
  padding-left: 2rem;
  font-family: 'Cairo';
}

.prize-input {
	border-radius: 0.5em;
	font-size: 1em;
	padding: 0.2em;
	margin-right: 0.1em;
	margin-left: 0.1em;
	margin-top: 0.1em;
	margin-bottom: 0.1em;
	color: black;
  text-align: right;
  font-family: 'Cairo';
}

.prize-submit-button-div {
  margin-top: 1rem;
}

.lang-icon {
  height: 2rem;
  width: 2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
